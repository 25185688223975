import config from "@/config/env";
import Amplitude from "@/helpers/amplitude";
import { fillTgLinkEmail } from "@/helpers/utilities";

export const openLink = (url: string) => {
  window.location.href = url;
};

export const onDashboard = (
  amplitudeEvent: string,
  url: string,
  data?: { [key: string]: string | number | null },
  userProperties?: { [key: string]: string | number | null },
  self?: boolean
) => {
  const hash = window.localStorage.getItem("hash");
  const { $i18n } = useNuxtApp();
  const locale = $i18n.locale.value;

  const hasQueryString = url.includes("?");
  let dashboardUrl = config.dashboardUrl + url;
  dashboardUrl += `&lang=${locale}`;

  if (hash) {
    dashboardUrl += `&hash=${hash}`;
  }

  if (!hasQueryString) {
    dashboardUrl = dashboardUrl.replace("&lang=", "?lang=");
  }

  // Base event
  logGoogleEvent(amplitudeEvent, data);

  try {
    if (userProperties) {
      Amplitude.Instance.setUserProperties(userProperties);
    }

    Amplitude.Instance.logEvent(
      amplitudeEvent,
      data,
      () => {
        openLink(dashboardUrl, self || true);
      },
      () => {
        console.log("err");
        console.error("Error: Amplitude.Instance.logEvent->errorCallback");
        openLink(dashboardUrl, self || true);
      }
    );
  } catch(e) {
    console.error("Error: Amplitude.Instance.logEvent->catch");
    openLink(dashboardUrl, true);
  }

};
export const onSocial = (
  amplitudeEvent: string,
  social: string,
  isSupport?: boolean,
  locale?: string,
  data?: { [key: string]: string | number | null }
) => {
  const socialLink = (
    social: string,
    isSupport: boolean | undefined,
    locale: string | undefined
  ) => {
    if (isSupport) {
      if (locale === "ru") {
        if (social === "telegram") {
          return fillTgLinkEmail(config.telegramSupport);
        }
        if (social === "skype") {
          return config.skypeSupport;
        }
        if (social === "whatsapp") {
          return `https://wa.me/${config.whatsappSupport}`;
        }
      } else {
        if (social === "telegram") {
          return fillTgLinkEmail(config.telegramSupport);
        }
        if (social === "skype") {
          return config.skypeSupport;
        }
        if (social === "whatsapp") {
          return `https://wa.me/${config.whatsappSupport}`;
        }
      }
    }
    // Not support - Channel
    else {
      if (locale === "ru") {
        if (social === "telegram") {
          return config.telegramChannelRu;
        }
        if (social === "twitter") {
          return config.twitterChannelRu;
        }
        if (social === "trustpilot") {
          return config.trustpilotLink;
        }
      } else {
        if (social === "telegram") {
          return config.telegramChannelEn;
        }
        if (social === "twitter") {
          return config.twitterChannelEn;
        }
        if (social === "trustpilot") {
          return config.trustpilotLink;
        }
      }
    }
    return "";
  };

  logGoogleEvent(amplitudeEvent, data);

  Amplitude.Instance.logEvent(
    amplitudeEvent,
    data,
    () => {
      openLink(socialLink(social, isSupport, locale), false);
    },
    () => {}
  );
};

export const onEvent = (
  amplitudeEvent: string,
  event?: (() => void | null) | null,
  data?: { [p: string]: string | number | null },
  userProperties?: { [p: string]: string | number | null }
) => {
  logGoogleEvent(amplitudeEvent, data);

  if (userProperties) {
    Amplitude.Instance.setUserProperties(userProperties);
  }
  Amplitude.Instance.logEvent(
    amplitudeEvent,
    data,
    () => {
      if (event && typeof event === "function") {
        event();
      }
    },
    () => {
      console.log("err");
      if (event && typeof event === "function") {
        event();
      }
    }
  );
};

export const onLink = (
  amplitudeEvent: string,
  url: string,
  data?: { [key: string]: string | number | null },
  self?: boolean
) => {
  const hash = window.localStorage.getItem("hash");
  const linkUrl = process.client && hash ? url + `?hash=${hash}` : url;

  logGoogleEvent(amplitudeEvent, data);

  // Base event
  Amplitude.Instance.logEvent(
    amplitudeEvent,
    data,
    () => {
      openLink(linkUrl, self || true);
    },
    () => {
      console.log("err");
      openLink(linkUrl, self || true);
    }
  );
};

export const onScroll = (el: string) => {
  const element = document.getElementById(el);

  window.scrollTo({
    top: 400,
    behavior: "smooth"
  });
};

const logGoogleEvent = async (eventName: string, data?: any) => {
  const finalEventName = eventName ? eventName.replace(/ /g, "_") : "";

  try {
    await window.dataLayer?.push({ event: `ganal_custom_${finalEventName}` });
  } catch (ex) {
    console.log(ex);
    console.error(
      `Cause: Google ` + `Reason: Not found ` + `Event: ${finalEventName}`
    );
  }
};

export const onAuth = async (
  amplitudeEvent: string,
  type: string,
  data?: { [key: string]: string | number | null }
) => {
  const hash = window.localStorage.getItem("hash");
  let authLink = "";
  if (type === "google") {
    try {
      // @ts-ignore
      const { data: googleLink } = await $fetch("/api/v1/oauth/google");
      const googleUrl = googleLink.google_url;
      authLink = hash
        ? config.dashboardUrl +
        "/redirect.html" +
        `?hash=${hash}&url=${encodeURIComponent(googleUrl)}`
        : encodeURI(googleUrl);
    } catch (ex) {
      console.log(ex);
    }
  }
  if (type === "apple") {
    try {
      // @ts-ignore
      const { data } = await $fetch("/api/v1/oauth/apple");
      const link = data.apple_url;
      authLink = hash
        ? config.dashboardUrl +
        "/redirect.html" +
        `?hash=${hash}&url=${encodeURIComponent(link)}`
        : encodeURI(link);
    } catch (ex) {
      console.log(ex);
    }
  }
  if (type === "telegram") {
    try {
      // @ts-ignore
      const { data } = await $fetch("/api/v1/dictionary/oauth");
      const link = "https://t.me/" + (data.bot_name || "");
      authLink = hash
        ? config.dashboardUrl + "/redirect.html" + `?hash=${hash}&url=${link}`
        : link;
    } catch (ex) {
      console.log(ex);
    }
  }

  if (type === "whatsapp") {
    try {
      // @ts-ignore
      const { data } = await $fetch("/api/v1/dictionary/oauth");
      const link = "https://wa.me/" + (data.whatsapp_bot || "");
      authLink = hash
        ? config.dashboardUrl + "/redirect.html" + `?hash=${hash}&url=${link}`
        : link;
    } catch (ex) {
      console.log(ex);
    }
  }

  logGoogleEvent(amplitudeEvent, data);

  Amplitude.Instance.logEvent(
    "create account started",
    data,
    () => {
    },
    () => {
      console.log("err");
    }
  );

  Amplitude.Instance.logEvent(
    amplitudeEvent,
    data,
    () => {
      openLink(authLink, true);
    },
    () => {
      console.log("err");
      openLink(authLink, true);
    }
  );
};


export const onYMEvent = async (eventName: string, eventType?: string) => {
  try {
    await window.ym(config.ymId, eventType || "reachGoal", eventName);
  } catch (ex) {
    console.error(
      `Error: ${eventName} not work`
    );
  }
  return;
};